<template>
  <div class="my-5" :class="$vuetify.breakpoint.name === 'lg' ? 'pr-9 pl-9' : 'px-5'">

    <v-card outlined class="box-shadow border-radius pa-2 pb-1 mt-5">
      <v-card-title class="body-1 px-5 text-first pt-0 font-weight-bold d-flex align-items">
        <div>
          DAFTAR INFORMASI PROMO
          <Export
            class="mr-2"
            :disabled="process.run"
            :file_type="'xls'"
            :btnText="'Export Excel'"
            :btnIcon="'mdi-microsoft-excel'"
            :btnColor="'teal'"
            :url="'sales/promo/export_excel'"
            :title="'Data Kode Promo'"
            ref="base-export-excel"
          />
        </div>
        <v-spacer></v-spacer>
        <!-- <v-text-field
          outlined
          dense
          color="#D31145"
          placeholder="Cari berdasarkan nama promo, tipe promo"
          class="font-weight-normal mr-3 mt-5"
          v-model="searching">
        </v-text-field> -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ml-3"
              v-on="on"
              v-bind="attrs"
              icon
              @click="dialog.filtered = true">
              <v-icon size="25">mdi-filter-outline</v-icon>
            </v-btn>
          </template>
          <span>Filter</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>

        <div v-if="items.length > 0">
          <v-data-table
            :headers="headers"
            :items="items"
            hide-default-footer
            disable-pagination 
            :options.sync="options"
            :loading="process.run"
            loading-text="Sedang memuat"
            no-data-text="Data tidak tersedia"
            no-results-text="Data tidak tersedia"
            sort-by-text="Urutkan berdasarkan"
            :items-per-page="10"
            fixed-header
            flat
            class="elevation-0 rounded-lg">

            <template v-slot:[`item.name`]="{item}">
              <v-list-item class="pl-0">

                <v-list-item-content>
                  <v-list-item-title class="subtitle-2">
                    {{ item.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="caption" :title="item.desc">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs}">
                        <div v-on="on" v-bind="attrs">
                          {{ item.desc | address }}
                        </div>
                      </template>
                      <span>
                        {{ item.desc }}
                      </span>
                    </v-tooltip>
                    
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>

            <template v-slot:[`item.package_type`]="{item}">

              <v-chip 
                v-if="item.package_type == 'Lite'"
                outlined
                label
                class="font-weight-bold mt-2"
                color="green">
                SODAPOS LITE
              </v-chip>
              <v-chip
                v-if="item.package_type == 'Pro'"
                outlined
                label
                class="font-weight-bold mt-2"
                color="red">
                SODAPOS PRO
              </v-chip>

              <div v-if="item.package_type == 'Semua'">
                <v-chip 
                  outlined
                  label
                  class="font-weight-bold mt-2"
                  color="teal darken-2">
                  SODAPOS PRO & LITE
                </v-chip>
              </div>
            </template>
            
            <template v-slot:[`item.status`]="{item}">
              <v-chip v-if="item.status == 'Belum Mulai'" color="error" class="font-weight-bold">
                <v-icon small left>mdi-calendar-lock-outline</v-icon>
                Belum Berlangsung
              </v-chip>
              <v-chip v-if="item.status == 'Mulai'" color="warning" class="font-weight-bold">
                <v-icon small left>mdi-calendar-clock-outline</v-icon>
                Sedang Berlangsung
              </v-chip>
              <v-chip v-if="item.status == 'Selesai'" color="success" class="font-weight-bold">
                <v-icon small left>mdi-calendar-check-outline</v-icon>
                Sudah Selesai
              </v-chip>
            </template>

            <template v-slot:[`item.total_code`]="{item}">
              <span class="subtitle-2 font-weight-normal black--text mt-3">{{ item.total_code | num_format }} Kode</span>
              <v-btn 
                :to="`/promo/detail-code/${item.id}`" 
                text
                small
                color="#0D47A1"
                class="subtitle-2 font-weight-normal text-capitalize">
                Lihat
              </v-btn>
            </template>

            <template v-slot:[`item.use_label`]="{item}">
              <div 
                class="font-weight-bold mb-2 text-decoration-underline c-p" 
                :class="item.repeat_bool ? 'teal--text' : 'red--text'"
                @click="dialog.information = true">
                {{ item.repeat_bool  ? "Berulang" : "Tidak Berulang"}}
              </div>
              <div v-html="item.use_label" class="subtitle-2"></div>
            </template>
            
            <template v-slot:[`item.discount_datetime`]="{item}">
              <div v-if="item.start_date !== '' && item.end_date !== ''">
                {{ item.start_date | dateFull }} - 
                {{ item.end_date | dateFull }}
              </div>
              <div v-else class="font-weight-bold pink--text text-uppercase">
                Berlaku Selamanya
              </div>
            </template>

            <template v-slot:[`item.action`]="{item}">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    color="red"
                    @click="actions(item, 'delete')"
                    icon>
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>
                  Hapus Promo
                </span>
              </v-tooltip>
              <!-- <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    color="teal"

                    :loading="$store.state.loading"
                    @click="actions(item, 'export')"
                    icon>
                    <v-icon>mdi-microsoft-excel</v-icon>
                  </v-btn>
                </template>
                <span>
                  Export Excel by Sales {{ item.sales_name }}
                </span>
              </v-tooltip> -->
            </template>

          </v-data-table>

          <Pagination 
            @changeLimit="limit = $event; fetch()" 
            @changePage="page = $event; fetch($event)" 
            :model="pagination"
            v-show="pagination.data.total_page > 0"/>
        </div>
        <div v-else class="text-center subtitle-2 mt-15">
          <Empty value="Data belum tersedia atau data yang Anda cari tidak tersedia"/>
        </div>
      </v-card-text>

    </v-card>

    <!-- DIALOG FILTER -->
    <v-dialog v-model="dialog.filtered" scrollable persistent max-width="550px">
      <v-card class="border-radius box-shadow">
        <v-card-title
          class="body-2 text-first d-flex mb-4"
          style="background-color: #d31145;"
        >
          <span class="white--text">
            Filter
          </span>
        </v-card-title>
        <v-card-text class="py-3" style="height: 550px;">
           <v-select
            dense
            outlined
            v-model="filter.package_type"
            color="#d31145"
            item-text="text"
            item-value="value"
            placeholder="Filter berdasarkan tipe paket"
            :items="[{text: 'SODAPOS PRO & LITE', value: 'Semua'}, {text: 'SODAPOS PRO', value: 'Pro'}, {text: 'SODAPOS LITE', value: 'Lite'}]">
          </v-select>
           <v-select
            dense
            outlined
            v-model="filter.status"
            color="#d31145"
            item-text="text"
            item-value="value"
            placeholder="Filter berdasarkan status promo"
            :items="[{text: 'Promo Belum Mulai', value: 'Belum Mulai'}, {text: 'Promo Sedang Berlangsung', value: 'Mulai'}, {text: 'Promo Sudah Berakhir', value: 'Selesai'}]">
          </v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            width="70"
            color="#d31145"
            elevation="0"
            :disabled="process.run"
            :loading="process.run"
            class="white--text text-capitalize"
            @click="filterData('filter')"
          >
            Filter
          </v-btn>
          <v-btn
            small
            width="70"
            elevation="0"
            color="orange"
            class="white--text text-capitalize"
            :disabled="process.run"
            @click="resetFilter()"
          >
            Reset
          </v-btn>
          <v-btn
            small
            width="70"
            elevation="0"
            class="text-capitalize"
            :disabled="process.run"
            @click="dialog.filtered = false;"
          >
            Tutup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ==== DIALOG SUCCESS ==== -->
    <v-dialog v-model="dialog.success" persistent width="425">
      <v-card class="border-radius box-shadow">
        <v-card-text class="py-5">
          <div class="text-center">
            <p
              class="font-weight-bold mb-1"
              style="font-size: 1.1rem !important;"
            >
              Anda telah berhasil {{ dialog.confirmation_type == 'delete' ? 'menghapus' : 'mengubah status' }} data promo
            </p>
            <p class="body-2 font-weight-regular">
              Silahkan klik tombol dibawah ini
            </p>
          </div>
          <v-card-actions>
            <v-spacer />
            <v-btn
              small
              outlined
              color="#d31145"
              class="text-capitalize"
              @click="dialog.success = false">
              Tutup
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- ==== DIALOG CONFIRMATION ==== -->
    <v-dialog v-model="dialog.confirmation" persistent width="500">
      <v-card class="border-radius box-shadow">
        <v-card-text class="py-5" style="height: 100px">
          <div class="text-center">
            <p
              class="font-weight-bold mb-1"
              style="font-size: 1.1rem !important;"
            >
              Apakah Anda ingin {{ dialog.confirmation_type == 'delete' ? 'menghapus' : 'mengubah status' }} data promo ini ?
            </p>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            width="150"
            outlined
            color="#d31145"
            class="text-capitalize"
            @click="dialog.confirmation = false; dialog.confirmation_type = ''; form.id = ''">
            Batal
          </v-btn>
          <v-btn
            width="150"
            color="#d31145"
            class="text-capitalize white--text"
            @click="deletePromo()">
            Hapus
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ==== DIALOG INFORMATION ==== -->
    <v-dialog v-model="dialog.information" width="500">
      <v-card class="border-radius box-shadow">
        <v-card-title></v-card-title>
        <v-card-text>
          <div>
            <b>Berulang</b>
            <p>Kode promo dapat digunakan berulang sampai batas yang telah ditentukan</p>
          </div>
          <div>
            <b>Tidak Berulang</b>
            <p>Kode promo tidak dapat digunakan secara berulang </p>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
      <div v-html="snackbar.text"></div>
    </v-snackbar>

  </div>
</template>

<script>
import { get, post, exportFile } from '@/service/Axios'
import moment from 'moment'
import Pagination from '@/components/Pagination'
import Export from '@/components/Export'
import Empty from '@/components/Empty'
export default {
  components: {
    Pagination,
    Export,
    Empty,
  },
  data () {
    return {
      start_date: false,
      end_date: false,
      searching: "",
      feedback: "",
      details: {},
      filter: {
        package_type: "",
        status: "",
      },
      process: {
        run: false
      },
      snackbar: {
        show: false,
        text: "",
        color: "success",
      },
      dialog: {
        information: false,
        confirmation: false,
        confirmation_type: "",
        success: false,
        form: false,
        form_type: "",
        filtered: false,
      },
      form: {
        sales_id: "",
        name: "",
        discount_array: [],
        desc: "",
        repeat_bool: "",
        repeat_count: 0,
        prefix: "",
        start_date: "",
        end_date: "",
        package_type_array: [],
      },
      sales: [],
      discount_array: [
        {
          value_percent: 0,
          qty: 0,
          commision_percent: 0,
          commision_calculate_bool: false,
          commision_after_disct_bool: false
        }
      ],
      // DATA TABLES
      items: [],
      limit: 10,
      page: 1,
      options:{},
      pagination: {
        data: {},
        page: ""
      },
      selected: {
        menu: 0,
        pagination: 0
      },
      headers: [
        {
          text: 'Aksi',
          value: 'action',
          align: 'center',
          width: 50
        },
        {
          text: 'Nama Promo',
          value: 'name',
          align: 'start',
          width: 250,
        },
        {
          text: 'Diskon',
          value: 'discount_label',
          align: 'center',
          width: 150
        },
        {
          text: 'Tipe Paket',
          value: 'package_type',
          align: 'center',
          width: 150
        },
        {
          text: 'Total Kode Promo',
          value: 'total_code',
          align: 'center',
          width: 200
        },
        {
          text: 'Penggunaan Kode',
          value: 'use_label',
          align: 'center',
          width: 200
        },
        {
          text: 'Status',
          value: 'status',
          align: 'center',
          width: 200
        },
        {
          text: 'Tanggal Promo Berlangsung',
          value: 'discount_datetime',
          align: 'center',
          width: 350
        },
      ],
    }
  },
  watch: {
    searching: function(val) {
      this.searching = val
      this.fetch()
    },
    'selected.pagination': function(val) {
      this.fetch(val+1)
    },
    options:{
      handler(){
        this.fetch()
      },
      deep:true
    },
  },
  computed: {
    FormatStartDate() {
      return this.form.start_date ? moment(this.form.start_date).format("DD MMMM YYYY") : ''
    },
    FormatEndDate() {
      return this.form.end_date ? moment(this.form.end_date).format("DD MMMM YYYY") : ''
    },
  },
  mounted() {
    this.fetch()
  },
  methods: {
    resetFilter() {
      this.filter = {
        package_type: "",
        status: "",
      };
      this.filterData('reset')
      return
    },

    filterData(type) {
      if (type == 'reset') {
        this.fetch()
      }else {
        this.fetch()
        this.dialog.filtered = false
      }
    },

    async fetch(paging) {
      this.process.run = true

      // console.log(this.options);

      // let sortby = "";
      // let sort = "";

      // if (
      //   this.options.sortBy &&
      //   this.options.sortBy.length > 0
      // ) {
      //   sortby = this.options.sortBy[0];
      // } else {
      //   sortby = "";
      // }

      // if (
      //   this.options.sortDesc &&
      //   this.options.sortDesc.length > 0
      // ) {
      //   if (this.options.sortDesc[0]) {
      //     sort = `-${sortby}`;
      //   } else {
      //     sort = `${sortby}`;
      //   }
      // } else {
      //   sort = "";
      // }

      await get('sales/promo', {
        params: {
          page: paging,
          limit: this.limit,
          search: this.searching,
          package_type: this.filter.package_type,
          status: this.filter.status
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.items = res.results.data
          this.totalData = res.results.pagination.total_data
          this.pagination.data = res.results.pagination
          this.pagination.page = res.results.pagination.total_page < this.page ? 1 : ""
          this.process.run = false
        }else {
          this.snackbar = {
            show: true,
            color: "red",
            text: "Terjadi kendala pada server."
          }
        }
      })
    },

    async fetchSales() {
      this.process.run = true

      await get('internal/dashboard/sales', {
        params: {
          pagination_bool: false,
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.sales = res.results.data
          this.process.run = false
        }
      })
    },

    async fetchPackageActivated() {
      this.process.run = true

      await get('internal/dashboard/sales/package-type', {
        params: {
          pagination_bool: false,
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.form.package_type_array = res.results.data
          this.process.run = false
        }
      })
    },

    actions(item, method) {
      switch(method) {
        case "add":
          this.dialog.form = true
          this.dialog.form_type = "add"
          this.form = {
            sales_id: "",
            name: "",
            discount_array: [
              {
                value_percent: 0,
                qty: 0,
                commision_percent: 0,
                commision_calculate_bool: false,
                commision_after_disct_bool: false
              }
            ],
            desc: "",
            repeat_bool: "",
            repeat_count: 0,
            prefix: "",
            start_date: "",
            end_date: "",
          }
          this.fetchSales()
          this.fetchPackageActivated()
          // this.$refs['validation-new-promo'].reset();
          break;
        case 'delete':
          this.details = item
          this.dialog.confirmation = true
          this.dialog.confirmation_type = "delete"
          break;
        case 'export':
          let url = 'internal/dashboard/sales/promo/export_excel'
          let params = {
            sales_id: item.sales_id
          }
          this.exportExcel(url, params,'Data Kode Promo by Sales','xls' )
          break;
      }
    },
    async deletePromo() {
      await post(`sales/promo/delete`,{
        data: {
          id: this.details.id,
        }
      }).then((response) => {
        let res = response.data
        if (res.status === 200) {
          this.dialog.confirmation = false
          this.dialog.success = true
          this.fetch()
        }else {
          this.snackbar = {
            show: true,
            color: "red",
            text: res.message ? res.message : "Gagal menghapus data promo"
          }
        }
      }).catch((error) => {
        this.snackbar = {
          show: true,
          color: "red",
          text: "Terjadi kendala pada server."
        }
      })
    },
  }
}
</script>