<template>
  <div class="my-5" :class="$vuetify.breakpoint.name === 'lg' ? 'pr-9 pl-9' : 'px-5'">

    <v-card outlined class="box-shadow border-radius pa-2 pb-1 mt-5">
      <v-card-title class="body-1 px-5 text-first pt-0 font-weight-bold">
        <div>
          DAFTAR PENARIKAN KOMISI
          <div class="mt-1 d-flex">
            <Export
              class="mr-2"
              :disabled="process.run"
              :file_type="'xls'"
              :btnText="'Export Excel'"
              :btnIcon="'mdi-microsoft-excel'"
              :btnColor="'teal'"
              :url="'sales/commision-withdraw/export_excel'"
              :title="'Data Komisi Sales'"
              ref="base-export-excel"
            />
            <v-btn
              elevation="0"
              color="#d31145"
              :fab="$vuetify.breakpoint.name === 'xs' ? true : false"
              :x-small="$vuetify.breakpoint.name === 'xs' ? true : false"
              class="white--text text-capitalize"
              @click="actions('withdraw', null)">
              <v-icon>mdi-credit-card-minus</v-icon>
              <span v-if="$vuetify.breakpoint.name !== 'xs'">
                Ajukan Penarikan
              </span>
            </v-btn>
          </div>
        </div>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>

        <v-card outlined class="box-shadow border-radius px-2 pt-2 pb-0" v-if="items.length > 0">
          <v-card-title class="body-1 px-5 text-first pt-0 font-weight-bold cursor-pointer">
            SUMMARY
            <v-spacer></v-spacer>
            <!-- <v-btn
              text
              elevation="0"
              class="text-capitalize pink--text"
              small>
              {{ is_show_summary ? "Sembunyikan Summary" : "Lihat Summary" }}
              <v-icon right>{{ is_show_summary ? "mdi-arrow-up" : "mdi-arrow-down"}}</v-icon>
            </v-btn> -->
          </v-card-title>
          <v-expand-transition>
            <v-row class="mb-2" v-show="is_show_summary">
              <v-col
                cols="12"
                sm="4"
                lg="4"
                md="4"
                xs="12"
                v-for="(item, index) in summary_withdraw"
                :key="index"
              >
                <v-skeleton-loader
                  class="ma-auto"
                  height="100"
                  :loading="process.run"
                  transition="scale-transition"
                  :tile="false"
                  type="image"
                >
                  <v-card 
                    :min-height="$vuetify.breakpoint.name === 'sm' ? 150 : 100"
                    class="box-shadow border-radius py-0">
                    <v-card-title class="subtitle-1 font-weight-bold">
                      {{ item.label }}
                      <v-spacer></v-spacer>
                      <v-tooltip left v-if="item.help">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="#D31145"
                            v-bind="attrs"
                            v-on="on"
                            class="cursor-pointer"
                            size="20"
                            >mdi-help-circle</v-icon
                          >
                        </template>
                        <div v-html="item.help"></div>
                      </v-tooltip>
                    </v-card-title>
                    <v-card-text class="subtitle-2">
                      <p
                        class="text-second d-flex align-center"
                        style="font-size: 25px;">
                        <span class="mr-3 mt-3">
                          {{ item.value }}
                        </span>
                        <v-spacer />
                        <span>
                          <v-icon size="40" :color="'primary'">mdi-cash</v-icon>
                        </span>
                      </p>
                    </v-card-text>
                  </v-card>
                </v-skeleton-loader>
              </v-col>
            </v-row>
          </v-expand-transition>
        </v-card>

        <div v-if="items.length > 0" class="mt-4">

          <v-row>
            <v-col cols="12" md="6" class="d-flex">
              <v-text-field
                outlined
                dense
                color="#D31145"
                placeholder="Cari berdasarkan nama bank, nama akun bank, nomor rekening bank"
                class="font-weight-normal mt-3"
                v-model="searching">
              </v-text-field>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mt-3 ml-3"
                    v-on="on"
                    v-bind="attrs"
                    icon
                    @click="dialog.filtered = true">
                    <v-icon size="25">mdi-filter-outline</v-icon>
                  </v-btn>
                </template>
                <span>Filter</span>
              </v-tooltip>
                  </v-col>
          </v-row>

          <v-data-table
            :headers="headers"
            :items="items"
            disable-sort
            hide-default-footer
            disable-pagination 
            :options.sync="options"
            :loading="process.run"
            loading-text="Sedang memuat"
            no-data-text="Data tidak tersedia"
            no-results-text="Data tidak tersedia"
            sort-by-text="Urutkan berdasarkan"
            :items-per-page="10"
            flat
            class="elevation-0 rounded-lg table-with-action">

            <template v-slot:[`item.commission`]="{item}">
              {{ item.commission }}
              <v-btn 
                @click="actions('sales_commission', item)"
                text
                small
                color="#0D47A1"
                class="subtitle-2 font-weight-normal text-capitalize">
                Lihat
              </v-btn>
            </template>

            <template v-slot:[`item.input_datetime`]="{item}">
              {{ item.input_datetime | datetime }}
            </template>

            <template v-slot:[`item.amount`]="{item}">
              {{ item.amount | price }}
            </template>

            <template v-slot:[`item.potongan_nominal`]="{item}">
              <div :class="item.status == 'Dibatalkan' || item.status == 'Ditolak' ? 'error--text text-decoration-line-through' : ''">{{ item.potongan_nominal | price }}</div>
            </template>

            <template v-slot:[`item.total_nominal`]="{item}">
              <div :class="item.status == 'Dibatalkan' || item.status == 'Ditolak' ? 'error--text text-decoration-line-through' : ''">{{ item.total_nominal | price }}</div>
            </template>

            <template v-slot:[`item.reject_note`]="{item}">
              <div :title="item.reject_note">{{ item.reject_note ? item.reject_note : item.sales_cancel_note ? item.sales_cancel_note : '-' }}</div>
            </template>

            <template v-slot:[`item.user`]="{item}">
              {{ item.reject_user_name ? item.reject_user_name : item.accept_user_name ? item.accept_user_name : item.sales_cancel_datetime ? "Pembatalan Mandiri" : "-" }}
            </template>

            <template v-slot:[`item.user_datetime`]="{item}">
              {{ (item.reject_datetime ? item.reject_datetime : item.sales_cancel_datetime ? item.sales_cancel_datetime : item.accept_datetime ? item.accept_datetime : "-") | datetime }}
            </template>
            
            <template v-slot:[`item.bank_name`]="{item}">
              <v-list-item class="px-0">
                <v-list-item-content>
                  <v-list-item-title class="subtitle-2">{{ item.bank_name + ' a.n ' + item.bank_account_name }}</v-list-item-title>
                  <v-list-item-subtitle class="caption">{{ item.bank_account_number }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>

            <template v-slot:[`item.status`]="{item}">
              <v-chip
                class="font-weight-bold"
                :color="item.status == 'Menunggu Diproses' ? 'warning' : item.status == 'Dibatalkan' || item.status == 'Ditolak' ? 'error' : 'success'">
                {{ item.status }}
              </v-chip>
            </template>

            <template v-slot:[`item.action`]="{item}">

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    :disabled="item.status != 'Menunggu Diproses'"
                    @click="actions('cancel_withdraw', item)"
                    color="red"
                    icon>
                    <v-icon>mdi-close-octagon</v-icon>
                  </v-btn>
                </template>
                <span>
                  Batalkan Pengajuan Penarikan Komisi
                </span>
              </v-tooltip>
            </template>

          </v-data-table>

          <Pagination 
            @changeLimit="limit = $event; fetch()" 
            @changePage="page = $event; fetch($event)" 
            :model="pagination"
            v-show="pagination.data.total_page > 0"/>
        </div>
        <div v-else class="text-center subtitle-2 mt-15">
          <Empty value="Data belum tersedia atau data yang Anda cari tidak tersedia"/>
        </div>
      </v-card-text>

    </v-card>

    <!-- ==== DIALOG SUCCESS ==== -->
    <v-dialog v-model="dialog.success" persistent width="425">
      <v-card class="border-radius box-shadow">
        <v-card-text class="py-5">
          <div class="text-center">
            <p
              class="font-weight-bold mb-1"
              style="font-size: 1.1rem !important;"
            >
              Anda telah berhasil {{ dialog.success_type == 'delete' ? 'menghapus' : 'mengubah status' }} akun
            </p>
            <p class="body-2 font-weight-regular">
              Silahkan klik tombol dibawah ini
            </p>
          </div>
          <v-card-actions>
            <v-spacer />
            <v-btn
              small
              outlined
              color="#d31145"
              class="text-capitalize"
              @click="dialog.success = false">
              Tutup
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- ==== DIALOG SUCCESS ==== -->
    <v-dialog v-model="dialog.confirmation" persistent width="425">
      <v-card class="border-radius box-shadow">
        <v-card-text class="py-5">
          <div class="text-center">
            <p
              class="font-weight-bold mb-1"
              style="font-size: 1.1rem !important;"
            >
              Apakah Anda ingin {{ dialog.confirmation_type == 'delete' ? 'menghapus' : 'mengubah status' }} akun intenal ini ?
            </p>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            large
            width="150"
            outlined
            color="#d31145"
            class="text-capitalize"
            @click="dialog.confirmation = false; dialog.confirmation_type = ''; form.id = ''">
            Batal
          </v-btn>
          <v-btn
            large
            width="150"
            color="#d31145"
            class="text-capitalize white--text"
            @click="deleteAccount()">
            Hapus
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
      <div v-html="snackbar.text"></div>
    </v-snackbar>

    <!-- FORM PENARIKAN -->
    <v-dialog v-model="dialog.withdraw" persistent scrollable max-width="600">
      <v-card>
        <v-card-title class="title">
          Form Penarikan Dana
          <v-spacer></v-spacer>
          <v-icon @click="dialog.withdraw = false" color="red"
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text
          :class="
            $vuetify.breakpoint.smAndDown
              ? 'subtitle-2 text-first pa-5'
              : 'subtitle-1 text-first pa-5'
          "
        >
          <v-simple-table>
            <template>
              <tbody>
                <tr>
                  <th class="text-second pl-0 body-2 font-weight-bold" width="30%">Jumlah Saldo</th>
                  <td width="5%" class="text-center text-second">:</td>
                  <td class="text-right">
                    <span class="teal--text font-weight-bold">{{ withdraw_check.saldo | price }}</span>
                  </td>
                </tr>
                <tr>
                  <th class="text-second pl-0" width="30%">Minimal Penarikan</th>
                  <td width="5%" class="text-center text-second">:</td>
                  <td class="text-right text-second">
                    {{ withdraw_check.minimal_withdraw | price }}
                  </td>
                </tr>
                <tr>
                  <th class="text-second pl-0" width="30%">Maksimal Penarikan</th>
                  <td width="5%" class="text-center text-second">:</td>
                  <td class="text-right text-second">
                    {{ withdraw_check.maksimal_withdraw | price }}
                  </td>
                </tr>
                <tr>
                  <th class="text-second pl-0" width="30%">{{ Object.keys(withdraw_check.potongan).length > 0 ? withdraw_check.potongan.title : ''  }}</th>
                  <td width="5%" class="text-center text-second">:</td>
                  <td class="text-right text-second">
                    <span class="red--text">{{ (Object.keys(withdraw_check.potongan).length > 0 ? withdraw_check.potongan.nominal : 0) | price }}</span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <span class="red--text caption">*Persyaratan di atas tidak mengikat, sewaktu - waktu dapat berubah.</span>
          <v-divider class="mb-3"></v-divider>

          Nominal yang ingin ditarik
          <div
            class="
              mt-2
              d-flex
              justify-center
              flex-wrap
              justify-sm-space-between
              justify-md-space-between
              justify-lg-space-between
              justify-xl-space-between
            "
          >
            <v-currency-field
                class="field__currency"
                :style="withdraw_check.saldo > withdraw_check.minimal_withdraw ? '' : 'max-width: 400px; min-width: 400px; width: 100%'"
                autocomplete="off"
                :rules="[rules.required, rules.minimal_withdraw, rules.maksimal_withdraw]"
                outlined
                dense
                prefix="Rp"
                id="price"
                color="#d31145"
                label="Silahkan masukkan nominal penarikan"
                v-model="form.amount"
              >
                <template v-if="form.amount != ''" v-slot:append>
                  <v-icon
                    @click="form.amount = ''"
                    color="red"
                    >mdi-close-circle</v-icon
                  >
                </template>
              </v-currency-field>
            <v-btn
              elevation="0"
              height="40"
              style="border-radius: 6px"
              class="ml-3 white--text rounded-lg text-capitalize"
              color="#f53b57"
              v-show="withdraw_check.saldo < withdraw_check.minimal_withdraw"
              @click="
                filter.status = 'all';
                form.amount = withdraw_check.saldo;
              ">
              <!-- :disabled="withdraw_check.minimal_receipt <= 100000" -->
              Semua Komisi
            </v-btn>
          </div>

          <!-- <div class="caption teal--text py-0 my-2" v-if="Object.keys(withdraw_check).length > 0">
            Nominal Penarikan belum termasuk {{ Object.keys(withdraw_check.potongan).length > 0 ? withdraw_check.potongan.title : ''  }} sebesar
            {{ (Object.keys(withdraw_check.potongan).length > 0 ? withdraw_check.potongan.nominal : 0) | price }}
          </div> -->

          <div class="text-center">
            <div class="body-1 font-weight-bold text--secondary">
              Sisa Komisi Anda sebesar
            </div>
            <div style="color: #00e676" class="text-h5 font-weight-bold my-3">
              {{
                (withdraw_check.saldo - form.amount > 0
                  ? withdraw_check.saldo - form.amount
                  : 0) | price
              }}
            </div>
          </div>

          <div class="subtitle-2 teal--text my-3">
            Perkiraan jumlah uang yang akan Anda terima sebesar 
            <span v-if="Object.keys(withdraw_check).length > 0">
              {{ 
                  (
                    form.amount > 0 && form.amount > withdraw_check.potongan.nominal  ? (form.amount - withdraw_check.potongan.nominal) : 0
                  )
                  | price 
              }}
            </span>
          </div>

          <!-- <div class="subtitle-2 teal--text my-3">
            Untuk penarikan dana silahkan gunakan shortcut di bawah ini
          </div> -->

          <!-- <div class="mt-5">
            <v-row>
              <v-col class="pr-3" cols="12" md="6">
                <v-btn
                  width="100%"
                  elevation="0"
                  height="38"
                  style="border-radius: 6px"
                  class="white--text rounded-lg"
                  :disabled="withdraw_check.minimal_receipt <= 100000"
                  color="#9b59b6"
                  @click="
                    filter.status = 'sebagian';
                    form.amount = 100000;
                  "
                >
                  Rp 100.000,-
                </v-btn>
              </v-col>
              <v-col class="px-3" cols="12" md="6">
                <v-btn
                  width="100%"
                  elevation="0"
                  height="38"
                  style="border-radius: 6px"
                  class="white--text rounded-lg"
                  :disabled="withdraw_check.minimal_receipt <= 200000"
                  color="#2ecc71"
                  @click="
                    filter.status = 'sebagian';
                    form.amount = 200000;
                  "
                >
                  Rp 200.000,-
                </v-btn>
              </v-col>
              <v-col class="px-3" cols="12" md="6">
                <v-btn
                  width="100%"
                  elevation="0"
                  height="38"
                  style="border-radius: 6px"
                  class="white--text rounded-lg"
                  :disabled="withdraw_check.minimal_receipt <= 500000"
                  color="#3498db"
                  @click="
                    filter.status = 'sebagian';
                    form.amount = 500000;
                  "
                >
                  Rp 500.000,-
                </v-btn>
              </v-col>
              <v-col class="px-3" cols="12" md="6">
                <v-btn
                  width="100%"
                  elevation="0"
                  height="38"
                  style="border-radius: 6px"
                  class="white--text rounded-lg"
                  :disabled="withdraw_check.minimal_receipt <= 1000000"
                  color="#3498db"
                  @click="
                    filter.status = 'sebagian';
                    form.amount = 1000000;
                  "
                >
                  Rp 1.000.000,-
                </v-btn>
              </v-col>
            </v-row>
          </div> -->

          <v-divider></v-divider>
          <div class="mt-3">

            <div class="d-flex justify-space-between align-center mb-5">
              Masukkan data Bank Anda
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    small
                    elevation="0"
                    class="text-capitalize"
                    v-bind="attrs"
                    :disabled="listSalesBank.length < 1"
                    v-on="on"
                  >
                    Pilih dari data bank saya
                    <v-icon right>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    three-line
                    v-for="(item, index) in listSalesBank"
                    :key="index"
                    @click="setSalesBank(item)">
                    <v-list-item-content>
                      <v-list-item-title>{{ item.bank_name }}</v-list-item-title>
                      <v-list-item-title>{{ item.account_name }}</v-list-item-title>
                      <v-list-item-subtitle>{{ item.account_number }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            
            <div>
              <v-select
                outlined
                dense
                placeholder="Silahkan pilih bank"
                color="#d31145"
                label="Nama bank"
                item-text="name"
                item-value="id"
                return-object
                v-model="form.ref_bank"
                :items="listBank">
              </v-select>

              <v-text-field
                outlined
                dense
                placeholder="Silahkan tuliskan nama akun bank"
                label="Nama akun bank"
                color="#d31145"
                return-object
                v-model="form.bank.account_name">
              </v-text-field>

              <v-text-field
                outlined
                dense
                placeholder="Silahkan tuliskan nomor rekening"
                label="Nomor rekening bank"
                color="#d31145"
                return-object
                v-model="form.bank.account_number">
              </v-text-field>
            </div>

          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pb-1">
          <v-spacer></v-spacer>
          <v-btn
            color="#d31145"
            elevation="0"
            :disabled="
              (process.run,
                form.amount > withdraw_check.saldo || 
              !form.amount)"
            :loading="process.run"
            class="white--text text-capitalize my-2"
            @click="withdrawRequest"
          >
            Ajukan Penarikan
          </v-btn>
          <v-btn
            width="70"
            elevation="0"
            class="text-capitalize"
            :disabled="process.run"
            @click="dialog.withdraw = false"
          >
            Batal
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG FILTER -->
    <v-dialog v-model="dialog.filtered" scrollable persistent max-width="550px">
      <v-card class="border-radius box-shadow">
        <v-card-title
          class="body-2 text-first d-flex mb-4"
          style="background-color: #d31145;"
        >
          <span class="white--text">
            Filter
          </span>
        </v-card-title>
        <v-card-text class="py-3" style="height: 550px;">
           
           <v-select
            dense
            outlined
            v-model="filter.status"
            color="#d31145"
            item-text="text"
            item-value="value"
            placeholder="Filter berdasarkan status penarikan"
            :items="[
              {text: 'Menunggu Diproses', value: 'Menunggu Diproses'}, 
              {text: 'Sudah Diproses', value: 'Sudah Diproses'}, 
              {text: 'Dibatalkan', value: 'Dibatalkan'},
              {text: 'Ditolak', value: 'Ditolak'},
            ]">
          </v-select>
          <VueCtkDateTimePicker
            color="#d31145"
            onlyDate
            :range="false"
            right
            v-model="filter.filter_date"
            label="Filter berdasarkan tanggal pengajuan"
            :noClearButton="true"
            format="YYYY-MM-DD"
            formatted="LL"
            locale="id"
            :max-date="new Date().toISOString().substr(0, 10)"
            :custom-shortcuts="[
              { key: 'today', label: 'Hari ini', value: 'day' },
              { key: 'thisWeek', label: 'Minggu ini', value: 'isoWeek' },
              { key: 'lastWeek', label: 'Minggu lalu', value: '-isoWeek' },
              { key: 'last7Days', label: '7 hari terakhir', value: 7 },
              { key: 'last30Days', label: '30 hari terakhir', value: 30 },
              { key: 'thisMonth', label: 'Bulan ini', value: 'month' },
              { key: 'lastMonth', label: 'Bulan lalu', value: '-month' },
              { key: 'thisYear', label: 'Tahun ini', value: 'year' },
              { key: 'lastYear', label: 'Tahun lalu', value: '-year' }
            ]"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            width="70"
            color="#d31145"
            elevation="0"
            :disabled="process.run"
            :loading="process.run"
            class="white--text text-capitalize"
            @click="filterData('filter')"
          >
            Filter
          </v-btn>
          <v-btn
            small
            width="70"
            elevation="0"
            color="orange"
            class="white--text text-capitalize"
            :disabled="process.run"
            @click="resetFilter()"
          >
            Reset
          </v-btn>
          <v-btn
            small
            width="70"
            elevation="0"
            class="text-capitalize"
            :disabled="process.run"
            @click="dialog.filtered = false;"
          >
            Tutup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG APPROVAL AND REJECT WITHDRAW -->
    <v-dialog v-model="dialog.withdraw_approval" scrollable persistent width="500">
      <v-card class="rounded-lg">
        <v-card-title class="subtitle-1">
          {{ dialog.withdraw_approval_type == "approve" ?  "Terima pengajuan penarikan komisi" : "Batalkan pengajuan penarikan komisi" }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="dialog.withdraw_approval = false;">
            <v-icon color="#e74c3c">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-subtitle class="caption">
          {{ dialog.withdraw_approval_type == "approve" ? "Terima pengajuan penarikan komisi" : "Batalkan pengajuan penarikan komisi" }}
          <v-scroll-y-transition>
            <v-alert
              dense
              outlined
              text
              icon="mdi-alert-circle-outline"
              color="#e74c3c"
              class="mb-0 mt-2"
              v-show="feedback.length > 0"
            >
              <div class="subtitle-2">{{ feedback }}</div>
            </v-alert>
          </v-scroll-y-transition>
        </v-card-subtitle>
        <v-divider></v-divider>
        <v-card-text class="pa-5" v-if="dialog.withdraw_approval_type == 'approve'">
          <div class="subtitle-2 font-weight-bold">
            Nominal Potongan atau Biaya Admin
          </div>
          <v-currency-field
            id="price"
            solo
            flat
            dense
            background-color="#EEEEEE"
            placeholder="Tuliskan nominal potongan"
            persistent-hint
            hint="Nominal potongan atau biaya admin dapat diubah"
            type="number"
            color="#d31145"
            v-model="form.tax_nominal"
          />
        </v-card-text>
        <v-card-text class="pa-5" v-else>
          <div class="subtitle-2 font-weight-bold">
            Catatan Pembatalan
          </div>
          <v-textarea
            solo
            flat
            dense
            background-color="#EEEEEE"
            placeholder="Tuliskan catatan pembatalan pengajuan penarikan komisi"
            color="#d31145"
            v-model="form.note">
          </v-textarea>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#d31145"
            depressed
            class="white--text text-none"
            :disabled="process.run"
            :loading="process.run"
            @click="saveRequest(dialog.withdraw_approval_type)"
          >
            <v-icon left>mdi-check-circle</v-icon>
            Simpan Data
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { get, post } from '@/service/Axios'
import Pagination from '@/components/Pagination'
import Export from '@/components/Export'
import Empty from '@/components/Empty'
export default {
  components: {
    Pagination,
    Export,
    Empty,
  },
  data () {
    return {
      searching: "",
      feedback: "",
      is_show_summary: true,
      details: {},
      summary_withdraw: [],
      withdraw_check: {
        potongan: {}
      },
      process: {
        run: false
      },
      dialog: {
        confirmation: false,
        confirmation_type: "",
        success: false,
        success_type: "",
        withdraw: false,
        withdraw_approval:false,
        withdraw_approval_type: "",
        filtered: false,
      },
      snackbar: {
        show: false,
        text: "",
        color: "success",
      },
      filter: {
        package_type: "",
        status: "",
        filter_date: ""
      },
      form : {
        id: "",
        note: "",

        // config
        tax_title: "",
        tax_nominal: 0,
        minimal: 0,
        maximal: 0,

        // WITHDRAW
        amount: "",
        potongan: "",
        ref_bank: {},
        bank: {
          id: "",
          ref_id: "",
          name: "",
          account_name: "",
          account_number: ""
        }
      },
      filter: {
        status: "",
        nominal_penarikan: "",
      },
      // DATA TABLES
      listBank: [],
      listSalesBank: [],
      items: [],
      limit: 10,
      page: 1,
      options:{},

      itemsCommission: [],
      limitCommission: 10,
      pageCommission: 1,
      optionsCommission:{},

      pagination: {
        commission: {
          data: {},
          page: ""
        },
        data: {},
        page: ""
      },
      selected: {
        commission: {
          pagination: 0
        },
        menu: 0,
        pagination: 0
      },
      headers: [
        {
          text: 'Aksi',
          value: 'action',
          align: 'center',
          width: 100
        },
        {
          text: 'Tanggal Pengajuan',
          value: 'input_datetime',
          align: 'center',
          width: 200
        },
        {
          text: 'Nominal Pengajuan',
          value: 'amount',
          align: 'end',
          width: 200
        },
        {
          text: 'Biaya Potongan',
          value: 'potongan_nominal',
          align: 'end',
          width: 200
        },
        {
          text: 'Nominal Diterima',
          value: 'total_nominal',
          align: 'end',
          width: 200
        },
        {
          text: 'Status',
          value: 'status',
          align: 'center',
          width: 200
        },
        {
          text: 'Tujuan Transfer',
          value: 'bank_name',
          align: 'start',
          width: 250
        },
        {
          text: 'Catatan Pembatalan',
          value: 'reject_note',
          align: 'start',
          width: 200
        },
        {
          text: 'User Pelaksana',
          value: 'user',
          align: 'start',
          width: 200
        },
        {
          text: 'Tanggal Pelaksana',
          value: 'user_datetime',
          align: 'start',
          width: 200
        }
      ],
      headersCommission: [
        {
          text: 'Tanggal Transaksi',
          value: 'transaction_datetime',
          align: 'center',
          width: 200
        },
        {
          text: 'Kode Promo',
          value: 'promo_code',
          align: 'center',
          width: 150
        },
        {
          text: 'Harga Paket',
          value: 'package_price',
          align: 'end',
          width: 150
        },
        {
          text: 'Diskon Paket',
          value: 'package_discount_value',
          align: 'end',
          width: 150
        },
        {
          text: 'Diskon Promo Sales',
          value: 'package_sales_promo_disc_value',
          align: 'end',
          width: 150
        },
        {
          text: 'Total Bayar Merchant',
          value: 'grand_total',
          align: 'end',
          width: 200
        },
        {
          text: 'Komisi Persen Sales',
          value: 'commission_percent',
          align: 'end',
          width: 150
        },
        {
          text: 'Dihitung Setelah Diskon',
          value: 'commission_after_disct_bool',
          align: 'center',
          width: 150
        },
        {
          text: 'Total Komisi Sales',
          value: 'commission_nominal',
          align: 'end',
          width: 150
        },
        {
          text: 'Nama Promo',
          value: 'promo_name',
          align: 'start',
          width: 200
        },
        {
          text: 'Nama Sales',
          value: 'sales_name',
          align: 'start',
          width: 200
        },
        {
          text: 'Nama Merchant',
          value: 'owner_name',
          align: 'start',
          width: 200
        },
        {
          text: 'Nama Toko',
          value: 'store_name',
          align: 'start',
          width: 200
        },
        {
          text: 'Aktivasi',
          value: 'package_type',
          align: 'center',
          width: 200
        }
      ],
      rules: {
        required: value => !!value || 'Kolom tidak boleh kosong.',
        minimal_withdraw: value => {
          const minimal_val = value ? value.replace('.','') : null
          // console.log('value',value);
          // console.log('minimal_val',minimal_val);
          // console.log('minimal_withdraw',this.withdraw_check.minimal_withdraw);
          // console.log('compare_min', minimal_val <= this.withdraw_check.minimal_withdraw);
          return parseInt(minimal_val) >= parseInt(this.withdraw_check.minimal_withdraw)  || `Nominal penarikan minimal ${this.$options.filters.price(this.withdraw_check.minimal_withdraw)}`
        },
        maksimal_withdraw: value => {
          const maksimal_val = value ? value.replace('.','') : null
          // console.log('value',value);
          // console.log('maksimal_val',maksimal_val);
          // console.log('maksimal_withdraw',this.withdraw_check.maksimal_withdraw);
          // console.log('compare_max', parseInt(maksimal_val) > parseInt(this.withdraw_check.maksimal_withdraw));
          return parseInt(maksimal_val) <= parseInt(this.withdraw_check.maksimal_withdraw)  || `Nominal penarikan maksimal ${this.$options.filters.price(this.withdraw_check.maksimal_withdraw)}`
        }
      }
    }
  },
  watch: {
    searching: function(val) {
      this.searching = val
      this.fetch()
    },
    'selected.pagination': function(val) {
      this.fetch(val+1)
    },
    options:{
      handler(){
        this.fetch()
      },
      deep:true
    },

    'form.ref_bank': function(val) {
      if (val.id != this.form.bank.ref_id) {
        this.form.bank.account_number = ''
        this.form.bank.id = ''
      }
    },
  },
  computed: {
    minimal_receipt() {
      return this.withdraw_check.minimal_receipt < 0
        ? 0
        : this.$options.filters.price(this.withdraw_check.minimal_receipt);
    },
    
    currency_rules() {
      let rules = ""
      if (this.withdraw_check.minimal_withdraw > 0 && this.withdraw_check.maksimal_withdraw > 0) {
        rules = `required|custom_min:${this.withdraw_check.minimal_withdraw}|custom_max:${this.withdraw_check.maksimal_withdraw}`;
      }else {
        rules = `required|custom_min:${this.withdraw_check.minimal_withdraw}`;
      }
      return rules;
    }
  },
  mounted() {
    this.fetch()
    this.fetchSalesWithdrawSummary()
  },
  methods: {
    actions(type, item) {
      console.log(item);
      switch (type) {
        case "cancel_withdraw":
          this.dialog.withdraw_approval = true
          this.dialog.withdraw_approval_type = "cancel_withdraw"
          this.form = {
            id: item.id,
            tax_nominal: 0,
            note: "",

            // config
            tax_title: "",
            tax_nominal: 0,
            minimal: 0,
            maximal: 0,

            // WITHDRAW
            amount: "",
            potongan: "",
            ref_bank: {},
            bank: {
              id: "",
              ref_id: "",
              name: "",
              account_name: "",
              account_number: ""
            }
          }
          break;
        case "withdraw":
          this.fetchConfigurationWithdraw()
          this.fetchSalesBank()
          this.fetchRefBank()
          break;
      }  
    },

    setSalesBank(item) {
      this.listBank.forEach((bank) => {
        if (item.ref_bank_id == bank.id) {
          this.form.ref_bank = bank
          return
        }
      });
      this.form.bank = {
        id: item.id,
        name: item.bank_name,
        ref_id: item.ref_bank_id ,
        account_name: item.account_name ,
        account_number: item.account_number 
      }
    },

    // WITHDRAW ACTION
    async fetchConfigurationWithdraw () {
      await get('sales/commision-withdraw/check')
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          if (res.results.data.is_can_withdraw_bool) {
            this.dialog.withdraw = true
            res.results.data.saldo = parseInt(res.results.data.saldo)
            this.withdraw_check = res.results.data
            this.form.amount = parseInt(res.results.data.saldo)
            this.form.potongan = parseInt(res.results.data.potongan.nominal)
          }else {
            this.snackbar = {
              show: true,
              text: res.results.data.is_can_withdraw_reason,
              color: "error",
            }
          }
        }
      })
    },

    async fetchSalesBank () {
      await get('sales/bank')
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.listSalesBank = res.results.data
        }
      })
    },

    async fetchRefBank () {
      await get('general/ref_bank')
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.listBank = res.results.data
        }
      })
    },

    async withdrawRequest() {
      this.form.bank.ref_id = this.form.ref_bank.id
      this.form.bank.name = this.form.ref_bank.name
      this.process.run = true

      await post(`sales/commision-withdraw`, {
        data: this.form
      }).then(response => {
        let res = response.data
        if(res.status == 200) {
          this.fetch()
          this.snackbar = {
            show: true,
            text: "Berhasil melakukan pengajuan penarikan komisi.",
            color: "success",
          }
          this.dialog.withdraw = false
          this.process.run = false
        }else {
          this.process.run = false
          this.snackbar = {
            show: true,
            text: res.message,
            color: "error",
          }
        }
      })
      this.process.run = false
    },

    // WITHDRAW ACTION END

    resetFilter() {
      this.filter = {
        status: "",
      };
      this.filterData('reset')
      return
    },

    filterData(type) {
      if (type == 'reset') {
        this.fetch()
      }else {
        this.fetch()
        this.dialog.filtered = false
      }
    },

    async fetch(paging) {
      this.process.run = true

      await get('sales/commision-withdraw', {
        params: {
          page: paging,
          limit: this.limit,
          search: this.searching,
          status: this.filter.status,
          input_datetime: this.filter.filter_date,
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.items = res.results.data
          this.totalData = res.results.pagination.total_data
          this.pagination.data = res.results.pagination
          this.pagination.page = res.results.pagination.total_page < this.page ? 1 : ""
          this.process.run = false
        }
      })

      this.process.run = false
    },
    
    async fetchSalesWithdrawSummary() {
      await get('sales/commision-withdraw/summary')
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.summary_withdraw = res.results.data
        }
      })
    },
    
    async fetchConfiguration() {
      await get('internal/dashboard/sales/commission/withdraw/config')
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.dialog.configuration_withdraw = true
          this.form = res.results.data
        }
      })
    },

    async saveRequest(type) {
      this.process.run = true
      let url = ""

      if (type == 'cancel_withdraw'){
        url = `sales/commision-withdraw/cancel`
      }

      await post(url, {
        data: this.form
      }).then(response => {
        let res = response.data
        if (res.status == 200) {
          this.fetch()
          this.dialog.withdraw_approval = false
          this.dialog.withdraw_approval_type = ""
          this.snackbar = {
            show: true,
            text: res.message,
            color: "success",
          }
        }else {
          this.snackbar = {
            show: true,
            text: res.message,
            color: "error",
          }
        }
      })
    }
  }
}
</script>