<template>
  <div class="my-5" :class="$vuetify.breakpoint.name === 'lg' ? 'pr-9 pl-9' : 'px-5'">

    <v-card outlined class="box-shadow border-radius pa-2 pb-1 mt-5">
      <v-subheader class="pb-0 pt-5 pl-5" style="height: 10px;">
        <v-btn
          to="/promo"
          text
          small
          color="#1B59F8"
          class="text-capitalize">
          <v-icon left>mdi-chevron-left</v-icon>
          Kembali
        </v-btn>
      </v-subheader>
      <v-card-title class="body-1 px-5 text-first py-0 font-weight-bold">
        DAFTAR INFORMASI KODE PROMO
        <v-spacer></v-spacer>
        <v-text-field
          outlined
          dense
          color="#D31145"
          placeholder="Cari berdasarkan kode promo"
          class="font-weight-normal"
          v-model="searching">
        </v-text-field>
      </v-card-title>
      <v-card-subtitle class="py-0 px-5 d-flex justify-space-between">
        <div class="subtitle-1 black--text my-2 d-flex " style="font-weight: 500">
          <div class="mr-5">
            Nama Promo : {{ detail_promo.name }} <br>
            Sales : {{ user.name }}
          </div>
          <v-divider vertical></v-divider>
          <div class="ml-5">
            <div class="d-flex align-center">
              Berlaku untuk aktivasi :
              <v-chip 
                v-if="detail_promo.package_type == 'Lite'"
                outlined
                label
                small
                class="font-weight-bold ml-3"
                color="green">
                SODAPOS LITE
              </v-chip>
              <v-chip
                v-if="detail_promo.package_type == 'Pro'"
                outlined
                label
                small
                class="font-weight-bold ml-3"
                color="red">
                SODAPOS PRO
              </v-chip>

              <div v-if="detail_promo.package_type == 'Semua'">
                <v-chip 
                  outlined
                  label
                  small
                  class="font-weight-bold ml-3"
                  color="teal darken-2">
                  SODAPOS PRO & LITE
                </v-chip>
              </div>
            </div>
            <div class="d-flex align-center">
              Berlaku : 
              <div v-if="detail_promo.start_date !== '' && detail_promo.end_date !== ''" class="ml-3">
                {{ detail_promo.start_date | dateFull }} - 
                {{ detail_promo.end_date | dateFull }}
              </div>
              <div v-else class="font-weight-bold pink--text text-uppercase ml-2">
                Selamanya
              </div>
            </div>
          </div>
        </div>
        <Export
          class="mr-2"
          :disabled="process.run"
          :file_type="'xls'"
          :btnText="'Export Excel'"
          :btnIcon="'mdi-microsoft-excel'"
          :btnColor="'teal'"
          :url="`sales/promo/export_excel?promo_id=${$route.params.id}`"
          :title="'Data Kode Promo'"
          ref="base-export-excel"
        />
      </v-card-subtitle>
      <v-card-text>

        <div v-if="items.length > 0">
          <v-data-table
            :headers="headers"
            :items="items"
            hide-default-footer
            disable-pagination 
            :options.sync="options"
            :loading="process.run"
            loading-text="Sedang memuat"
            no-data-text="Data tidak tersedia"
            no-results-text="Data tidak tersedia"
            sort-by-text="Urutkan berdasarkan"
            :items-per-page="10"
            fixed-header
            flat
            class="elevation-0 rounded-lg">

            <template v-slot:[`item.name`]="{item}">
              <v-list-item class="pl-0">

                <v-list-item-content>
                  <v-list-item-title class="subtitle-2">
                    {{ item.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <template v-slot:[`item.sales_name`]="{item}">
              <v-list-item class="pl-0">

                <v-list-item-content>
                  <v-list-item-title class="subtitle-2">
                    {{ item.sales_name }}
                  </v-list-item-title>
                  <v-list-item-subtitle v-html="item.sales_email" class="caption"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
            
            <template v-slot:[`item.status`]="{item}">
              <v-chip v-if="item.status == 'Belum Digunakan'" color="error" class="font-weight-bold">
                <v-icon small left>mdi-calendar-lock-outline</v-icon>
                Belum Digunakan
              </v-chip>
              <v-chip v-if="item.status == 'Sudah Digunakan'" color="success" class="font-weight-bold">
                <v-icon small left>mdi-calendar-check-outline</v-icon>
                Sudah Digunakan
              </v-chip>
            </template>

            <template v-slot:[`item.use_count`]="{item}">
              <a class="subtitle-2 font-weight-normal">
                {{ item.use_count | num_format }} Kode
              </a>
            </template>

            <template v-slot:[`item.commission_percent`]="{item}">
              Komisi {{ item.commission_percent | percent }}
              <div 
                class="font-weight-bold mb-2 text-decoration-underline c-p mt-2" 
                :class="item.commission_after_disct_bool ? 'teal--text' : 'red--text'"
                @click="dialog.information = true">
                {{ item.commission_after_disct_bool  ? "Setelah Diskon" : "Sebelum Diskon"}}
              </div>
            </template>
            
            <template v-slot:[`item.discount_datetime`]="{item}">
              {{ item.start_date | dateFull }} - 
              {{ item.end_date | dateFull }}
            </template>

            <template v-slot:[`item.action`]="{item}">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    color="red"
                    @click="actions(item, 'delete_code')"
                    icon>
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>
                  Hapus Kode Promo
                </span>
              </v-tooltip>
            </template>

          </v-data-table>

          <Pagination 
            @changeLimit="limit = $event; fetch()" 
            @changePage="page = $event; fetch($event)" 
            :model="pagination"
            v-show="pagination.data.total_page > 0"/>
        </div>
        <div v-else class="text-center subtitle-2 mt-15">
          <Empty value="Data belum tersedia atau data yang Anda cari tidak tersedia"/>
        </div>
      </v-card-text>

    </v-card>

    <!-- ==== DIALOG SUCCESS ==== -->
    <v-dialog v-model="dialog.success" persistent width="425">
      <v-card class="border-radius box-shadow">
        <v-card-text class="py-5">
          <div class="text-center">
            <p
              class="font-weight-bold mb-1"
              style="font-size: 1.1rem !important;"
            >
              Anda telah berhasil {{ dialog.confirmation_type == 'delete_code' ? 'menghapus' : 'mengubah status' }} kode promo
            </p>
            <p class="body-2 font-weight-regular">
              Silahkan klik tombol dibawah ini
            </p>
          </div>
          <v-card-actions>
            <v-spacer />
            <v-btn
              small
              outlined
              color="#d31145"
              class="text-capitalize"
              @click="dialog.success = false">
              Tutup
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- ==== DIALOG CONFIRMATION ==== -->
    <v-dialog v-model="dialog.confirmation" persistent width="500">
      <v-card class="border-radius box-shadow">
        <v-card-text class="py-5" style="height: 100px">
          <div class="text-center">
            <p
              class="font-weight-bold mb-1"
              style="font-size: 1.1rem !important;"
            >
              Apakah Anda ingin {{ dialog.confirmation_type == 'delete_code' ? 'menghapus' : 'mengubah status' }} kode promo ini ?
            </p>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            width="150"
            outlined
            color="#d31145"
            class="text-capitalize"
            @click="dialog.confirmation = false; dialog.confirmation_type = ''; form.id = ''">
            Batal
          </v-btn>
          <v-btn
            width="150"
            color="#d31145"
            class="text-capitalize white--text"
            @click="deleteCodePromo()">
            Hapus
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- === DIALOG INFORMATION === -->
    <v-dialog v-model="dialog.information" width="500">
      <v-card class="border-radius box-shadow">
        <v-card-title></v-card-title>
        <v-card-text>
          <div>
            <b>Setelah Diskon</b>
            <p>Perhitungan komisi sales akan dihitung setelah diskon paket, diskon promo</p>
          </div>
          <div>
            <b>Sebelum Diskon</b>
            <p>Perhitungan komisi sales akan dihitung dari harga awal paket</p>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
      <div v-html="snackbar.text"></div>
    </v-snackbar>

  </div>
</template>

<script>
import { get, post } from '@/service/Axios'
import moment from 'moment'
import Pagination from '@/components/Pagination'
import Export from '@/components/Export'
import Empty from '@/components/Empty'
export default {
  components: {
    Pagination,
    Export,
    Empty,
  },
  data () {
    return {
      searching: "",
      process: {
        run: false
      },
      snackbar: {
        show: false,
        text: "",
        color: "success",
      },
      dialog: {
        information:false,
        confirmation: false,
        confirmation_type: "",
        success: false,
        form: false,
        form_type: "",
      },
      // DATA TABLES
      detail_promo: {},
      items: [],
      limit: 10,
      page: 1,
      options:{},
      pagination: {
        data: {},
        page: ""
      },
      selected: {
        menu: 0,
        pagination: 0
      },
      headers: [
        {
          text: 'Nama Kode Promo',
          value: 'name',
          align: 'start',
          width: 250
        },
        {
          text: 'Kode Promo',
          value: 'code',
          align: 'center',
          width: 150
        },
        {
          text: 'Diskon Promo',
          value: 'discount_percent_label',
          align: 'center',
          width: 150
        },
        {
          text: 'Komisi Sales',
          value: 'commission_percent',
          align: 'center',
          width: 150
        },
        {
          text: 'Status',
          value: 'status',
          align: 'center',
          width: 200
        },
        {
          text: 'Jumlah Digunakan',
          value: 'label_use',
          align: 'center',
          width: 200
        },
        {
          text: 'Aksi',
          value: 'action',
          align: 'center',
          width: 150
        }
      ],
    }
  },
  watch: {
    searching: function(val) {
      this.searching = val
      this.fetch()
    },
    'selected.pagination': function(val) {
      this.fetch(val+1)
    },
    options:{
      handler(){
        this.fetch()
      },
      deep:true
    },
  },
  computed: {
    user() {
      return JSON.parse(localStorage.getItem("access_user"))
    },
    FormatStartDate() {
      return this.form.start_date ? moment(this.form.start_date).format("DD MMMM YYYY") : ''
    },
    FormatEndDate() {
      return this.form.end_date ? moment(this.form.end_date).format("DD MMMM YYYY") : ''
    },
  },
  mounted() {
    this.fetch()
    this.fetchPromo()
  },
  methods: {
    async fetch(paging) {
      this.process.run = true

      await get('sales/promo/code', {
        params: {
          page: paging,
          limit: this.limit,
          search: this.searching,
          promo_id: this.$route.params.id
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.items = res.results.data
          this.totalData = res.results.pagination.total_data
          this.pagination.data = res.results.pagination
          this.pagination.page = res.results.pagination.total_page < this.page ? 1 : ""
          this.process.run = false
        }
      })
    },

    async fetchPromo() {
      this.process.run = true

      await get('sales/promo', {
        params: {
          id: this.$route.params.id
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
           this.detail_promo = res.results.data[0]
          this.process.run = false
        }
      })
    },

    actions(item, method) {
      switch(method) {
        case 'delete_code':
          this.details = item
          this.dialog.confirmation = true
          this.dialog.confirmation_type = "delete_code"
          break;
      }
    },

    async deleteCodePromo() {
      await post(`sales/promo/code/delete`,{
        data: {
          id: this.details.id,
        }
      }).then((response) => {
        let res = response.data
        if (res.status === 200) {
          this.dialog.confirmation = false
          this.dialog.success = true
          this.fetch()
        }else {
          this.snackbar = {
            show: true,
            color: "red",
            text: "Gagal menghapus data kode promo"
          }
        }
      }).catch((error) => {
        this.snackbar = {
          show: true,
          color: "red",
          text: "Terjadi kendala pada server."
        }
      })
    },
  }
}
</script>